import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "../../shared-functions";
import { Row } from "react-bootstrap";
import Moment from "react-moment";
import { DotsVerticalIcon, DrawingPinFilledIcon, DrawingPinIcon, Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { Card, DropdownMenu, Heading, IconButton, Text } from "@radix-ui/themes";

const Note = (props) => {

  const navigate = useNavigate();
  const isPageWide = useMediaQuery('(min-width: 640px)');

  const [note, setNote] = useState(null);
  const [favorite, setPinned] = useState(false);

  useEffect(() => {
    setNote(props.note);
    setPinned(props.note.is_pinned || false);
  }, [props]);

  if (note === null) {
    return null;
  }

  return (
    <div style={{ width: isPageWide ? '60%' : `calc(100% - 16px)`, marginLeft: isPageWide ? `calc(20% - 32px)` : 0, marginTop: 10 }}>
      <Card style={{ padding: 20 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
          <div style={{ width: `calc(100% - 48px)` }}>
            <Heading size={isPageWide ? '2' : '2'} style={{ marginBottom: 0, cursor: 'pointer' }} onClick={() => navigate('/note/' + note.id)}>{note.title}</Heading>
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
              <Text color='gray' size='1' align='left' style={{ marginRight: 10 }}><Moment date={note.created_at} fromNow /></Text>
              {note.tags.map((tag, index) => (
                <Text key={index} size='1' color="gray" style={{ marginRight: 5 }}>#{tag}</Text>
              ))}
            </Row>
          </div>
          <div style={{ width: 24, textAlign: 'right' }}>
            <IconButton size='1' variant="ghost" color={favorite ? 'accent' : 'gray'} onClick={() => { setPinned(!favorite); props.onPinned(note.id, !favorite); }}>
              {favorite ? <DrawingPinFilledIcon /> : <DrawingPinIcon />}
            </IconButton>
          </div>
          <div style={{ width: 24, textAlign: 'right' }}>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <IconButton size='1' variant="ghost" color='gray'>
                  <DotsVerticalIcon />
                </IconButton>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => navigate('/note/' + note.id)}><Pencil1Icon /> Edit</DropdownMenu.Item>
                <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { setPinned(!favorite); props.onPinned(note.id, !favorite); }}><DrawingPinFilledIcon /> {favorite ? 'Remove pin' : 'Add a pin'}</DropdownMenu.Item>
                <DropdownMenu.Item color="red" style={{ cursor: 'pointer' }} onClick={() => props.onTrashNote(note.id)}><TrashIcon /> Move to trash</DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </div>
        </Row>
      </Card>
    </div>
  )
}

export default Note;