import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, Button, Dialog, TextField, VisuallyHidden, Spinner, IconButton } from '@radix-ui/themes';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { Row } from 'react-bootstrap';
import Moment from 'react-moment';

const SearchButton = (props) => {

    const navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [notes, setNotes] = useState([]);
    const [open, setOpen] = useState(false);
    const [searchResults, setSearchResults] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setNotes(props.notes || []);
    }, [props]);

    const runSearch = () => {
      if (search.length > 0) {
        setLoading(true);
        setSearchResults(null);
        const results = notes.filter((note) => {
          return note.title.toLowerCase().includes(search.toLowerCase()) || note.tags.join(',').toLowerCase().includes(search.toLowerCase());
        });
        setSearchResults(results);
        setLoading(false);
      }
    }

    return (
      <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Button variant="soft" color='gray' size='2' style={{ marginRight: 0 }}>
          <MagnifyingGlassIcon />
        </Button>
      </Dialog.Trigger>
      <Dialog.Content minHeight="500px" maxHeight="500px" aria-describedby="search-notes" style={{ overflowY: 'hidden' }}>
        <VisuallyHidden>
          <Dialog.Title>Search notes</Dialog.Title>
          <Dialog.Description>Search for notes by title or tags</Dialog.Description>
        </VisuallyHidden>
        <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginBottom: 10 }}>
          <div style={{ width: `calc(100% - 46px)` }}>
            <TextField.Root size='2' value={search} onChange={(e) => { setSearch(e.target.value); }} onKeyDown={(e) => { if (e.key === 'Enter' && search.length > 0) { runSearch(); } }} placeholder="Search notes...">
              <TextField.Slot>
              <MagnifyingGlassIcon height="16" width="16" />
              </TextField.Slot>
            </TextField.Root>
          </div>
          <div style={{ width: 46, textAlign: 'right' }}>
            <IconButton size='2' variant="soft" color='gray' onClick={runSearch}><MagnifyingGlassIcon /></IconButton>
          </div>
        </Row>
        { loading && (
          <div style={{ marginTop: 16, width: '100%', height: 420, overflowY: 'auto' }}>
            <Spinner />
          </div>
        )}
        { searchResults && searchResults.length === 0 && (
          <div style={{ marginTop: 16, width: '100%', height: 420, overflowY: 'auto' }}>
            <Text size='2' align='center'>No results</Text>
          </div>
        )}
        { searchResults && searchResults.length > 0 && (
          <div style={{ marginTop: 16, width: '100%', height: 420, overflowY: 'auto' }}>
            {searchResults.map((result, index) => {
              if (result.title.toLowerCase().includes(search.toLowerCase()) || result.tags.join(',').toLowerCase().includes(search.toLowerCase())) {
              return (
                <div key={index} style={{ paddingRight: 10 }}>
                  <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid var(--gray-a4)' }}>
                    <div style={{ width: '100%' }}>
                      <Text size='2' weight='bold' as='p' style={{ cursor: 'pointer', marginBottom: 0 }} onClick={() => navigate('/note/' + result.id)}>{result.title}</Text>
                      <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
                        <Text size='1' color='gray' style={{ marginRight: 5 }}><Moment fromNow>{result.created_at}</Moment></Text>
                        { result.tags.map((tag, tagIndex) => {
                          return (
                            <Text key={tagIndex} size='1' color='gray' style={{ marginRight: 5 }}>#{tag}</Text>
                          )
                        })}
                      </Row>
                    </div>
                  </Row>
                </div>
              )
            }
            return null;
            })}
          </div>
        )}
        </Dialog.Content>
      </Dialog.Root>
    )

  }

export default SearchButton;