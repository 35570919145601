import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequireAuth } from './use-require-auth.js';
import { secondsToHMS, useMediaQuery } from './shared-functions.js';
import { Col, Row } from 'react-bootstrap';
import { ThemeContext } from "./Theme.js";
import { Text, IconButton, DropdownMenu, Spinner, Button, Heading } from '@radix-ui/themes';
import Logo from './components/common/Logo.js';
import { dbCreateNote, dbGetNotes, dbTrashNote, dbUpdateNote } from './utilities/sqldb.js';
import { MODELS_LIBRARY } from './config/models.js';
import toast, { Toaster } from 'react-hot-toast';
import Profile from './components/common/Profile.js';
// import CloudAudioRecorder from './components/home/CloudAudioRecorder.js';
// import CloudAudioStreamRecorder from './components/home/CloudAudioStreamRecorder.js';
import { ArrowsDownUp, Circle, Microphone, Plus, Square } from '@phosphor-icons/react';
import { DEFAULT_NOTE_LANGUAGE, DEFAULT_NOTE_TEMPLATE, DEFAULT_NOTE_TONE, NOTE_TEMPLATES, PROMPT_SYSTEM_CREATE_NOTE } from './config/app.js';
import Note from './components/home/Note.js';
import SearchButton from './components/home/SearchButton.js';
import { v4 as uuidv4 } from 'uuid';
import Footer from './components/common/Footer.js';
import SidebarComponent from './components/common/Sidebar.js';
import { checkSubscriptionStatus } from './utilities/usage.js';
import { jsonrepair } from 'jsonrepair';
// import Import from './components/home/Import.js';
import NewNote from './components/home/NewNote.js';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { ArrowRightIcon, DrawingPinFilledIcon, DrawingPinIcon } from '@radix-ui/react-icons';
import DurationClock from './components/home/DurationClock.js';

export default function Home() {

  const auth = useRequireAuth();

  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  let isPageWide = useMediaQuery('(min-width: 640px)');

  const [notes, setNotes] = useState(null);
  const [filter, setFilter] = useState('all');
  const [sort, setSort] = useState('updated_newest');
  const [search, setSearch] = useState('');
  const [inferenceModel, setInferenceModel] = useState(MODELS_LIBRARY[1]);
  const [defaultNoteTemplate, setDefaultNoteTemplate] = useState(DEFAULT_NOTE_TEMPLATE);
  const [defaultNoteTone, setDefaultNoteTone] = useState(DEFAULT_NOTE_TONE);
  const [defaultNoteLanguage, setDefaultNoteLanguage] = useState(DEFAULT_NOTE_LANGUAGE);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);

  const startTimeRef = useRef(null);
  const noteIdRef = useRef(null);
  const transcriptionRef = useRef(null);
  const transcriptDivRef = useRef(null);
  const dirtyRef = useRef(false);
  const [recording, setRecording] = useState(false);

  const {
    transcript,
    interimTranscript,
    resetTranscript,
    browserSupportsSpeechRecognition,
    browserSupportsContinuousListening
  } = useSpeechRecognition();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth && auth.user) {
      initialize();
    }
  }, [auth]);

  // Initialize notes
  const initialize = async () => {
    const _notes = await dbGetNotes(auth.user.id);
    if (_notes) {
      // setNotes([]);
      setNotes(_notes.sort((a, b) => b.updated_at - a.updated_at));
    }
    if (auth.user.default_note_template) {
      setDefaultNoteTemplate(auth.user.default_note_template);
    }
    if (auth.user.default_note_tone) {
      setDefaultNoteTone(auth.user.default_note_tone);
    }
    if (auth.user.default_note_language) {
      setDefaultNoteLanguage(auth.user.default_note_language);
    }
    // Check subscription status
    checkSubscriptionStatus(auth.user).then((res) => {
      setSubscriptionStatus(res);
    })
    setLoading(false);
  }

  useEffect(() => {
    if (transcript) {
      transcriptionRef.current = transcript;
      
      // Add this to auto-scroll
      if (transcriptDivRef.current) {
        transcriptDivRef.current.scrollTop = transcriptDivRef.current.scrollHeight;
      }
    }
  }, [transcript]);

  // Trash note
  const onTrashNote = async (noteId) => {
    dbTrashNote(noteId, auth.user.id).then((res) => {
      if (res) {
        let _notes = [];
        notes.map((n) => {
          if (n.id !== noteId) {
            _notes.push(n);
          }
          return null;
        })
        setNotes(_notes.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
        toast.success('Moved to trash');
      } else {
        toast.error('Sorry, could not move to trash.');
      }
    })
  }

  // Pinned note
  const onPinned = async (noteId, favorite) => {
    dbUpdateNote(noteId, { is_pinned: favorite }).then((res) => {
      if (res) {
        toast.success(favorite ? 'Pinned' : 'Removed pin');
        let _notes = [];
        notes.map((n) => {
          if (n.id !== noteId) {
            _notes.push(n);
          } else {
            _notes.push({ ...n, is_pinned: favorite });
          }
          return null;
        })
        setNotes(_notes.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
      }
    })
  }

  // Sort notes
  const onSort = (value) => {
    setSort(value);
    let _notes = notes.sort((a, b) => {
      if (value === 'created_newest') {
        return new Date(b.created_at) - new Date(a.created_at);
      } else if (value === 'created_oldest') {
        return new Date(a.created_at) - new Date(b.created_at);
      } else if (value === 'updated_newest') {
        return new Date(b.updated_at) - new Date(a.updated_at);
      } else if (value === 'updated_oldest') {
        return new Date(a.updated_at) - new Date(b.updated_at);
      } else {
        return 0;
      }
    });
    setNotes(_notes);
  }

  // Start recording
  const startRecording = async () => {

    if (!subscriptionStatus && auth.user.email !== 'amit.jardosh@gmail.com') {
      toast((t) => (
        <div style={{ textAlign: 'left' }}>
          <div style={{ width: '100%' }}>
            <Text size="2" style={{ marginRight: 10 }}>Sorry, you have exceeded your free recording limit. Please upgrade to a paid subscription to continue.</Text>
          </div>
          <div style={{ width: '100%', marginTop: 10, textAlign: 'left' }}>
            <Button variant="solid" size="2" onClick={() => navigate('/billing')}>Upgrade to Pro <ArrowRightIcon /></Button>
          </div>
        </div>
      ),
        {
          duration: 5000,
          position: 'top-center'
        }
      );
      return;
    }

    if (!browserSupportsSpeechRecognition) {
      toast.error('Your browser does not support speech recognition');
      return;
    }

    // Set start time
    startTimeRef.current = Date.now();

    // Reset transcript
    resetTranscript();
    transcriptionRef.current = '';

    // Create new note
    let noteId = uuidv4();
    noteIdRef.current = noteId;
    let note = await createNewNote(noteId);

    // Start recording
    setRecording(true);

    // Start speech recognition
    if (browserSupportsContinuousListening) {
      SpeechRecognition.startListening({ continuous: true });
    } else {
      SpeechRecognition.startListening();
    }

  };

  // Stop recording
  const stopRecording = async () => {

    // Stop speech recognition
    SpeechRecognition.stopListening();

    // Set loading to true
    setLoading(true);

    // Set recording to false
    setRecording(false);

    // Transform transcription into a note
    if (transcriptionRef.current && noteIdRef.current) {
      let content = await transformTranscriptionIntoNote(defaultNoteTemplate, transcriptionRef.current);
      if (content) {
        // Update note with LLM result
        await dbUpdateNote(noteIdRef.current, { title: content.title, note: content.note });
      }
      navigate('/note/' + noteIdRef.current);
      setLoading(false);
      reset();
    } else {
      setLoading(false);
      reset();
      toast.error('Sorry, something went wrong');
    }

  }

  // Reset
  const reset = () => {
    resetTranscript();
    transcriptionRef.current = '';
    noteIdRef.current = null;
    dirtyRef.current = false;
  }

  // Set transcriptionRef on transcript change
  useEffect(() => {
    if (transcript) {
      transcriptionRef.current = transcript;
      dirtyRef.current = true;
    }
  }, [transcript]);

  // Upload transcript every 5 seconds
  useEffect(() => {
    const interval = setInterval(async () => {
      if (transcriptionRef.current && transcriptionRef.current.length > 0 && noteIdRef.current && dirtyRef.current) {
        let res = await dbUpdateNote(noteIdRef.current, { transcript: transcriptionRef.current });
        if (res) {
          dirtyRef.current = false;
        }
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // Create new note
  const createNewNote = async (noteId) => {

    let note = await dbCreateNote({
      id: noteId,
      title: 'New note',
      template: defaultNoteTemplate,
      note: '<p>Write something</p>',
      tags: [],
      transcript: '',
      created_by: auth.user.id,
      created_at: new Date(),
      updated_at: new Date()
    });

    return note;

  }

  // Create blank note
  const createBlankNote = async () => {

    let noteId = uuidv4();

    let note = await dbCreateNote({
      id: noteId,
      title: 'Note on ' + new Date().toLocaleDateString(),
      template: defaultNoteTemplate,
      note: NOTE_TEMPLATES.find(t => t.name === defaultNoteTemplate).sample,
      tags: [],
      transcript: '',
      created_by: auth.user.id,
      created_at: new Date(),
      updated_at: new Date()
    })

    if (note) {
      navigate('/note/' + noteId);
    } else {
      toast.error('Sorry, could not create new note');
    }
  }

  // Transform transcription into a note
  const transformTranscriptionIntoNote = async (template, transcript) => {

    try {

      // Call LLM
      let responseLLM = await fetch(process.env.REACT_APP_RUN_VERBANOTES_LOCALLY === 'true' ? inferenceModel.testChatApi : inferenceModel.prodChatApi, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_AUTHORIZATION_CODE}`
        },
        body: JSON.stringify({
          model: inferenceModel,
          messages: [{ role: "system", content: PROMPT_SYSTEM_CREATE_NOTE + ' ' + NOTE_TEMPLATES.find(t => t.name === template).format_instructions + ' Here is a sample of the note format: ' + NOTE_TEMPLATES.find(t => t.name === template).sample }, { role: "user", content: 'Transform the following transcription into a note in the ' + defaultNoteLanguage + ' language using a ' + defaultNoteTone + ' tone. TRANSCRIPTION: ' + transcript }],
          user_id: auth.user.id
        })
      });

      if (responseLLM.status === 200) {
        let responseJson = await responseLLM.json();
        if (responseJson) {
          let repairedJson = jsonrepair(responseJson);
          let content = JSON.parse(repairedJson);
          return content;
        } else {
          toast.error('Sorry, could not prepare your note');
          return { title: 'New note', note: '<p>' + (transcript ? transcript : 'Write something') + '</p>' };
        }
      } else {
        toast.error('Sorry, could not prepare your note');
        return { title: 'New note', note: '<p>' + (transcript ? transcript : 'Write something') + '</p>' };
      }

    } catch (error) {
      toast.error('Sorry, could not prepare your note');
      return { title: 'New note', note: '<p>' + (transcript ? transcript : 'Write something') + '</p>' };
    }

  }

  if (!auth || !auth.user || loading || !notes) {
    return (
      <div style={{ position: 'fixed', top: 80, width: '100vw', height: `calc(100% - 100px)` }}>
        <Logo navigate={false} />
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner size="2" />
        </Row>
      </div>
    )
  }

  if (recording) {
    return (
      <div style={{ position: 'fixed', bottom: isPageWide ? 100 : 40, width: isPageWide ? 300 : 300, marginLeft: isPageWide ? `calc(50% - 150px)` : `calc(50% - 150px)`, zIndex: 1000 }}>
        <Logo navigate={false} />
        <div
          ref={transcriptDivRef}
          style={{
            width: '100%', 
            height: isPageWide ? 300 : 200, 
            textAlign: 'center', 
            marginTop: 20, 
            overflowY: 'auto', 
            padding: 10,
            display: 'flex',
            flexDirection: 'column-reverse'
          }}
        >
          {transcript && transcript.length > 0 && (
            <Text size='2' style={{ color: 'var(--gray-11)' }}>{transcript}</Text>
          )}
        </div>
        <div className="pulsating-icon" style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
          <Circle size={20} weight='fill' color='crimson' />
        </div>
        <div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
          <DurationClock startTime={startTimeRef.current} />
        </div>
        <div style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
          <IconButton size='2' variant="outline" color='red' radius='full' style={{ width: 160, height: 40 }} onClick={() => stopRecording()}>
            <Square size={16} weight='fill' />
          </IconButton>
        </div>
      </div>
    )
  }

  // if (recording) {
  //   return (
  //     <div style={{ position: 'fixed', top: 80, width: '100vw', height: `calc(100% - 100px)` }}>
  //       <Logo navigate={false} />
  //       <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
  //         <Col xs={11} sm={10} md={9} lg={6} xl={4} xxl={4} style={{ padding: 10, textAlign: 'center' }}>
  //           <div className="pulsating-icon" style={{ marginBottom: 3 }}>
  //             <Circle size={20} weight='fill' color='crimson' />
  //           </div>
  //           <div style={{ width: '100%', textAlign: 'center', marginTop: 10 }}>
  //             <DurationClock startTime={startTimeRef.current} />
  //           </div>
  //           <Button variant="soft" color='gray' size='3' style={{ marginTop: 20 }} onClick={() => stopRecording()}>End recording</Button>
  //           <div style={{ width: '100%', height: 200, textAlign: 'center', marginTop: 20, overflowY: 'auto', padding: 10 }}>
  //             {interimTranscript && interimTranscript.length > 0 && (
  //               <Text size='2' style={{ color: 'var(--gray-11)' }}>{interimTranscript} ...</Text>
  //             )}
  //             {!interimTranscript || interimTranscript.length === 0 && (
  //               <Text size='2' style={{ color: 'var(--gray-11)' }}>Transcription will appear here</Text>
  //             )}
  //           </div>
  //         </Col>
  //       </Row>
  //     </div>
  //   )
  // }

  return (
    <div style={{ width: '100%' }}>

      <Logo navigate={false} />
      <Profile />
      <NewNote />
      <SidebarComponent />

      <div style={{ position: 'fixed', bottom: isPageWide ? 100 : 40, width: isPageWide ? 160 : 160, marginLeft: isPageWide ? `calc(50% - 80px)` : `calc(50% - 80px)`, zIndex: 1000 }}>
        <IconButton size='2' variant="solid" color='red' radius='full' style={{ width: '100%', height: 40 }} onClick={() => startRecording()}>
          <Microphone size={16} weight='fill' /> <Text size='3' style={{ marginLeft: 4 }}>Record</Text>
        </IconButton>
      </div>

      <div style={{ position: 'fixed', top: 50, width: isPageWide ? `calc(60% - 16px)` : `calc(100% - 64px)`, marginLeft: isPageWide ? '20%' : 48 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginBottom: 10 }}>
          <Col xs={6} sm={6} md={6} lg={8} xl={8} xxl={8} style={{ padding: 0, paddingRight: 10 }}>
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5 }}>
              <Heading size='4' style={{ marginBottom: 0, marginRight: 5 }}>All notes</Heading>
              {/* <Button size='2' variant="solid" color='red' style={{ marginLeft: 10 }} onClick={() => startRecording()}>
                <Microphone size={16} weight='fill' />{isPageWide && 'Record'}
              </Button> */}
              {/* <IconButton size='2' variant="soft" color='gray' style={{ marginLeft: 10 }} onClick={() => createBlankNote()}>
                <Plus size={16} weight='regular' />
              </IconButton> */}
            </Row>
          </Col>
          <Col xs={6} sm={6} md={6} lg={4} xl={4} xxl={4} style={{ padding: 0, paddingLeft: 10, textAlign: 'right' }}>
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
              <IconButton size='2' variant="soft" color={filter === 'pinned' ? 'accent' : 'gray'} style={{ marginRight: 10 }} onClick={() => { filter === 'pinned' ? setFilter('all') : setFilter('pinned'); }}>
                { filter === 'pinned' ? <DrawingPinFilledIcon /> : <DrawingPinIcon /> }
              </IconButton>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger style={{ marginRight: 10 }}>
                  <IconButton size='2' variant="soft" color='gray'>
                    <ArrowsDownUp />
                  </IconButton>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content align="start">
                  <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { onSort('created_newest'); }}>Created date (newest first)</DropdownMenu.Item>
                  <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { onSort('created_oldest'); }}>Created date (oldest first)</DropdownMenu.Item>
                  <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { onSort('updated_newest'); }}>Updated date (newest first)</DropdownMenu.Item>
                  <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { onSort('updated_oldest'); }}>Updated date (oldest first)</DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
              <SearchButton notes={notes} />
            </Row>
          </Col>
        </Row>
      </div>

      {notes.length === 0 && (
        <div style={{ position: 'fixed', top: 110, width: isPageWide ? `calc(60% - 16px)` : `calc(100% - 64px)`, marginLeft: isPageWide ? '20%' : 48 }}>
          <Text size='2' align='center' color='gray'>No notes</Text>
        </div>
      )}

      {notes.length > 0 &&
        <div style={{ position: 'fixed', top: 100, width: `calc(100% - 48px)`, marginLeft: 48, height: `calc(100% - 120px)`, paddingBottom: 20 }}>
          <div style={{ height: '100%', overflowY: 'scroll' }}>
            {
              notes.map((note, index) => {
                if (filter === 'all' || (filter === 'pinned' && note.is_pinned)) {
                  if (search === '' || note.title.toLowerCase().includes(search.toLowerCase()) || note.tags.join(',').toLowerCase().includes(search.toLowerCase())) {
                    return (
                      <Note key={index} note={note} userId={auth.user.id} inferenceModel={inferenceModel} defaultNoteTemplate={defaultNoteTemplate} defaultNoteTone={defaultNoteTone} defaultNoteLanguage={defaultNoteLanguage} onTrashNote={onTrashNote} onPinned={onPinned} />
                    )
                  }
                }
                return null;
              })
            }
            <Footer />
          </div>
        </div>
      }

      <Toaster position='bottom-right' toastOptions={{ className: 'toast', style: { background: 'var(--gray-3)', color: 'var(--gray-11)' } }} />

    </div>
  )

}


// { notes.length > 0 && false &&
//   <div style={{ position: 'fixed', top: 60, width: isPageWide ? `calc(60% - 16px)` : `calc(100% - 64px)`, marginLeft: isPageWide ? '20%' : 48 }}>
//    <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginBottom: 10 }}>
//      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} style={{ padding: 0, paddingRight: 10 }}>
//        <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5 }}>
//          <SegmentedControl.Root defaultValue="all" size="2" onValueChange={(value) => { setFilter(value); }}>
//            <SegmentedControl.Item value="all">All</SegmentedControl.Item>
//            <SegmentedControl.Item value="pinned">Pinned</SegmentedControl.Item>
//          </SegmentedControl.Root>
//          <DropdownMenu.Root>
//            <DropdownMenu.Trigger style={{ marginLeft: 10 }}>
//              <IconButton size='2' variant="soft" color='gray'>
//                <ArrowsDownUp />
//              </IconButton>
//            </DropdownMenu.Trigger>
//            <DropdownMenu.Content align="start">
//              <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { onSort('created_newest'); }}>Created date (newest first)</DropdownMenu.Item>
//              <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { onSort('created_oldest'); }}>Created date (oldest first)</DropdownMenu.Item>
//              <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { onSort('updated_newest'); }}>Updated date (newest first)</DropdownMenu.Item>
//              <DropdownMenu.Item style={{ cursor: 'pointer' }} onClick={() => { onSort('updated_oldest'); }}>Updated date (oldest first)</DropdownMenu.Item>
//            </DropdownMenu.Content>
//          </DropdownMenu.Root>
//        </Row>
//      </Col>
//      <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{ padding: 0, paddingLeft: 10, textAlign: 'right' }}>
//        <SearchButton notes={notes} />
//      </Col>
//    </Row>
//  </div>
// }