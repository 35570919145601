import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from "./use-auth.js";
import { useNavigate } from 'react-router-dom';
import Footer from "./components/common/Footer.js";
import { Row, Col, Spinner, Image } from "react-bootstrap";
import { supabaseClient } from './config/supabase.js';
import { ThemeContext } from './Theme.js';
import { validateEmail } from './helpers/string.js';
import toast, { Toaster } from 'react-hot-toast';
import { Envelope } from '@phosphor-icons/react';
import { useMediaQuery } from './helpers/dom.js';
import { Button, Text, TextField } from '@radix-ui/themes';

export default function Auth(props) {

  const auth = useAuth();
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);

  let isPageWide = useMediaQuery('(min-width: 960px)');

  const [email, setEmail] = useState('');
  const [showMagicLinkModal, setShowMagicLinkModal] = useState(false);

  useEffect(() => {
    if (auth.user !== null) {
      navigate('/home');
    }
    window.scrollTo(0, 0);
  }, [auth]);

  useEffect(() => {

    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange((_event, session) => {
      // console.log('event session', _event);
      if (_event === 'INITIAL_SESSION') {
        // handle initial session
        auth.getOrCreateUser(session);
      } else if (_event === 'SIGNED_IN') {
        // handle sign in event
      } else if (_event === 'SIGNED_OUT') {
        auth.signOut();
        // handle sign out event
      } else if (_event === 'PASSWORD_RECOVERY') {
        auth.getOrCreateUser(session);
        // handle password recovery event
      } else if (_event === 'TOKEN_REFRESHED') {
        auth.getOrCreateUser(session);
        // handle token refreshed event
      } else if (_event === 'USER_UPDATED') {
        // handle user updated event
        auth.getOrCreateUser(session);
      }
    })

    return () => subscription.unsubscribe();

  }, []);

  const sendMagicLink = async () => {
    if (validateEmail(email)) {
      const { data, error } = await supabaseClient.auth.signInWithOtp({
        email: email,
        options: {
          shouldCreateUser: true,
        },
      })
      if (error) {
        toast.error('Something did not work. Try again.')
      } else {
        setEmail('');
        toast.success('Check your email for a magic link.')
      }
    } else {
      toast.error('Enter a valid email')
    }
  };

  if (auth.user) {
    return (
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: isPageWide ? -220 : 0, marginRight: -40, height: '80vh' }}>
        <Col style={{ textAlign: 'center' }}>
          <Spinner animation="border" role="status" style={{ marginTop: 20 }} />
        </Col>
      </Row>
    )
  } else {
    return (
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', marginLeft: 0, marginRight: 0 }}>
        <Col xs={12} sm={12} md={6} lg={7} xl={7} style={{ padding: 20, textAlign: 'center' }}>
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 40, marginBottom: 40 }}>
            <div style={{ textAlign: 'center', maxWidth: 300 }}>
              <div style={{}}>
                <Image src={"/logo.png"} style={{ width: 60 }} />
                <Text size='4' weight='bold' as='div' style={{ marginTop: 10 }}>{ process.env.REACT_APP_RUN_VERBANOTES_LOCALLY === 'true' ? 'Verba' : 'Verbanotes' }</Text>
              </div>
              <div style={{ marginTop: 40 }}>
                {process.env.REACT_APP_RUN_VERBANOTES_LOCALLY === 'true' ?
                  <Button variant='soft' color='gray' size='3' onClick={() => {
                    supabaseClient.auth.signInWithOAuth({
                      provider: 'google',
                      options: {
                        redirectTo: 'http://localhost:3000'
                      }
                    })
                  }}><Image src={"/assets/buttons/google_icon.png"} style={{ width: 20, marginRight: 12 }} />Continue with Google</Button>
                  :
                  <Button variant='soft' color='gray' size='3' onClick={() => {
                    supabaseClient.auth.signInWithOAuth({
                      provider: 'google',
                    })
                  }}><Image src={"/assets/buttons/google_icon.png"} style={{ width: 20, marginRight: 12 }} />Continue with Google</Button>
                }
              </div>
              <div style={{ marginTop: 20 }}>
                <Text size='2' color='gray' weight='light'>or</Text>
              </div>
              <div style={{ marginTop: 20, width: '100%' }}>
                <TextField.Root size="3" variant='surface' color='gray' style={{ width: '100%' }} placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <Button variant='soft' size='3' color='gray' onClick={sendMagicLink} style={{ marginTop: 10, width: '100%', textAlign: 'left' }}>
                  <Envelope size={20} weight="regular" style={{ marginRight: 2 }} />Email me a magic link
                </Button>
              </div>
            </div>
            <Footer />
            <Toaster position='top-center' toastOptions={{ className: 'toast', style: { background: 'var(--gray-3)', color: 'var(--gray-11)' } }} />
          </Row>
        </Col>
      </Row>
    )
  }
  
}

