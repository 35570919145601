import { Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { secondsToHMS } from "../../shared-functions";

// Duration clock
const DurationClock = (props) => {

  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {

    const interval = setInterval(() => {
      setElapsedTime(Date.now() - props.startTime);
    }, 1000);

    return () => clearInterval(interval);

  }, [props.startTime]);

  return (
      <div>
        <Text size='2' style={{ color: 'var(--gray-11)' }}>
          {secondsToHMS(elapsedTime / 1000)}
        </Text>
      </div>
  )

}

export default DurationClock;