import React from "react"
import { useLocation, useNavigate } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import Logo from "./Logo.js";
import { useMediaQuery } from "../../helpers/dom.js";
import { Sidebar, Menu, MenuItem, menuClasses } from 'react-pro-sidebar';
import { SquaresFour, Gear, ChartBar, CreditCard } from "@phosphor-icons/react";

export default function SidebarComponent(props) {

  const location = useLocation();
  const navigate = useNavigate();
  let isPageWide = useMediaQuery('(min-width: 960px)');

  if (location.pathname === '/') {
    return (
      <div></div>
    )
  }

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, height: '100%', overflow: 'hidden', width: 46, textAlign: 'center' }}> 
      <Sidebar 
          collapsed={true}
          width="220px"
          collapsedWidth="60px" 
          backgroundColor="transparent"
          rootStyles={{
            fontFamily: 'Inter Medium',
            border: '0px solid transparent',
            fontSize: '1em',
            marginTop: '48px',
            height: '100vh',
            overflowY: 'hidden',
          }}
          >
          
          <Menu
              rootStyles={{
                ['.' + menuClasses.button]: {
                  backgroundColor: 'transparent',
                  color: 'var(--gray-11)',
                  padding: '0px 15px 0px 5px',
                  height: '40px',
                  '&:hover': {
                    backgroundColor: 'var(--gray-3)',
                  },
                  [`&.ps-active`]: {
                    color: 'var(--accent-11)',
                    fontFamily: 'Inter Medium',
                    backgroundColor: 'transparent',
                  },
                },
                ['.' + menuClasses.icon]: {
                  marginRight: '0px',
                  [`&.ps-active`]: {
                    color: 'var(--accent-11)',
                  },
                },
              }}
              >

              <MenuItem 
                label="Notes"
                active={location.pathname === '/home' ? true : false} 
                icon={<SquaresFour size={22} weight="regular" />}
                rootStyles={{
                  ['.' + menuClasses.button]: {
                    marginBottom: '0px',
                  },
                }}
                onClick={() => navigate("/home")}>
              </MenuItem>

              {/* <MenuItem 
                label="Usage"
                active={location.pathname === '/usage' ? true : false} 
                icon={<ChartBar size={22} weight="regular" />}
                rootStyles={{
                  ['.' + menuClasses.button]: {
                    marginBottom: '0px',
                  },
                }}
                onClick={() => navigate("/usage")}>
              </MenuItem> */}

              {/* <MenuItem 
                label="Billing"
                active={location.pathname === '/billing' ? true : false} 
                icon={<CreditCard size={22} weight="regular" />}
                rootStyles={{
                  ['.' + menuClasses.button]: {
                    marginBottom: '0px',
                  },
                }}
                onClick={() => navigate("/billing")}>
              </MenuItem> */}

              <MenuItem 
                label="Settings"
                active={location.pathname === '/settings' ? true : false} 
                icon={<Gear size={22} weight="regular" />}
                rootStyles={{
                  ['.' + menuClasses.button]: {
                    marginBottom: '0px',
                  },
                }}
                onClick={() => navigate("/settings")}>
              </MenuItem>


            </Menu>
          
        </Sidebar>
    </div>
  )

}
